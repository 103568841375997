import { Api } from '../../../../api/src/Api';
import { Injectable } from '@angular/core';
import { RefreshTokenService } from '../../../../shared/services/refresh-token.service';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: `root` })
export class AuthService {
    constructor(private readonly apiService: Api, private readonly refreshTokenService: RefreshTokenService) {}

    async isAuthenticated() {
        let token = localStorage.getItem(`token`);
        if (!token) {
            return false;
        }

        const decodedToken = jwt_decode(token) as any;

        if (decodedToken?.exp * 1000 < Date.now()) {
            await this.refreshTokenService.refreshUser();
            token = localStorage.getItem(`token`) ?? ``;
        }

        const meResponse = await this.apiService.users
            .usersControllerGetMe({
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .catch(() => {
                //
            });

        return Boolean(meResponse);
    }

    async isLoggedIn() {
        const token = localStorage.getItem(`token`);

        if (!token) {
            return false;
        }

        const meResponse = await this.apiService.users
            .usersControllerGetMe({
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .catch(() => {
                //
            });
        return Boolean(meResponse);
    }

    async isAdmin() {
        let token = localStorage.getItem(`admin-token`);

        if (!token) {
            return false;
        }

        const decodedToken = jwt_decode(token) as any;

        if (decodedToken?.exp * 1000 < Date.now()) {
            await this.refreshTokenService.refreshAdmin();
            token = localStorage.getItem(`admin-token`) ?? ``;
        }

        const adminResponse = await this.apiService.admin
            .adminControllerGetMe({
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .catch(() => {
                //
            });

        return Boolean(adminResponse);
    }

    async isLoggedInAdmin() {
        let token = localStorage.getItem(`admin-token`);

        if (!token) {
            return false;
        }

        const decodedToken = jwt_decode(token) as any;

        if (decodedToken?.exp * 1000 < Date.now()) {
            await this.refreshTokenService.refreshAdmin();
            token = localStorage.getItem(`admin-token`) ?? ``;
        }

        const adminResponse = await this.apiService.admin
            .adminControllerGetMe({
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .catch(() => {
                //
            });

        return Boolean(adminResponse);
    }
}
