/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-continue */
import * as fns from 'date-fns';

import { getParsedTime } from './time-parser';

type ResolveCustomRangeOptions = ResolveCustomRangeDateOptions | ResolveCustomRangeWeekOptions;

interface TimeRange<T> {
    from: T[];
    to: T[];
}

interface SingleTimeRange {
    from: string;
    to: string;
}

interface ResolveCustomRangeDateOptions {
    type: `DATE`;
    activationDate: Date;
    timeRange: TimeRange<string | Date>;
}

interface ResolveCustomRangeWeekOptions {
    type: `WEEK`;
    count?: number;
    activationDate: Date;
    duration?: string;
    timeRange: TimeRange<string>;
}

interface WeekDay {
    name: string;
    referenceNumber: number;
}

const weekDays: WeekDay[] = [
    {
        name: `monday`,
        referenceNumber: 1,
    },
    {
        name: `tuesday`,
        referenceNumber: 2,
    },
    {
        name: `wednesday`,
        referenceNumber: 3,
    },
    {
        name: `thursday`,
        referenceNumber: 4,
    },
    {
        name: `friday`,
        referenceNumber: 5,
    },
    {
        name: `saturday`,
        referenceNumber: 6,
    },
    {
        name: `sunday`,
        referenceNumber: 0,
    },
];

export const convertWeekOccurenceString = (
    input: SingleTimeRange,
    activationDate: Date,
    occurences?: number,
    duration?: string,
) => {
    // get 22:00:00 from 'friday 22:00:00'
    const fromRanges: any[] = [];
    const toRanges: any[] = [];

    // split string
    const timeFrom = input.from.split(` `);
    const timeTo = input.to.split(` `);

    // get weekday names
    const [weekDayNameFrom] = timeFrom;
    const [weekDayNameTo] = timeTo;

    // get weekday numbers
    const weekDayFrom = weekDays.find((day) => {
        return day.name === weekDayNameFrom;
    });

    const weekDayTo = weekDays.find((day) => {
        return day.name === weekDayNameTo;
    });

    if (!weekDayFrom || !weekDayTo) {
        return undefined;
    }

    // get times
    const timeVariablesFrom = timeFrom[1].split(`:`);
    const timeVariablesTo = timeTo[1].split(`:`);

    const myTimeFrom = {
        weekDayNumber: weekDayFrom.referenceNumber as any,
        seconds: Number(timeVariablesFrom[2]),
        minutes: Number(timeVariablesFrom[1]),
        hours: Number(timeVariablesFrom[0]),
    };

    const myTimeTo = {
        weekDayNumber: weekDayTo.referenceNumber as any,
        seconds: Number(timeVariablesTo[2]),
        minutes: Number(timeVariablesTo[1]),
        hours: Number(timeVariablesTo[0]),
    };

    // get from and to dates (with hours)
    let dateFrom = new Date(
        activationDate.getFullYear(),
        activationDate.getMonth(),
        activationDate.getDate(),
        myTimeFrom.hours,
        myTimeFrom.minutes,
        myTimeFrom.seconds,
    );

    let dateTo = new Date(
        activationDate.getFullYear(),
        activationDate.getMonth(),
        activationDate.getDate(),
        myTimeTo.hours,
        myTimeTo.minutes,
        myTimeTo.seconds,
    );

    let validUntil: Date | undefined;

    if (duration) {
        validUntil = getParsedTime(activationDate, duration);
    }

    // with occurence
    if (occurences) {
        for (let i = 0; i < occurences; i++) {
            dateFrom = fns.nextDay(dateFrom, myTimeFrom.weekDayNumber);
            dateTo = fns.nextDay(dateTo, myTimeTo.weekDayNumber);

            if (i === 0 && dateFrom > dateTo) {
                toRanges.push(dateTo);
                fromRanges.push(activationDate);

                dateFrom = fns.add(dateFrom, { days: -7 });

                continue;
            }

            if (validUntil && validUntil < dateTo) {
                toRanges.push(validUntil);
                fromRanges.push(dateFrom);
                break;
            }

            toRanges.push(dateTo);
            fromRanges.push(dateFrom);
        }
    }

    // without occurence = duration

    if (!occurences && validUntil) {
        let j = 0;

        while (j >= 0) {
            j = +1;
            dateFrom = fns.nextDay(dateFrom, myTimeFrom.weekDayNumber);
            dateTo = fns.nextDay(dateTo, myTimeTo.weekDayNumber);

            if (j === 1 && dateFrom > dateTo) {
                if (validUntil > activationDate && validUntil < dateTo) {
                    toRanges.push(validUntil);
                    fromRanges.push(activationDate);
                    break;
                }

                toRanges.push(dateTo);
                fromRanges.push(activationDate);

                dateFrom = fns.add(dateFrom, { days: -7 });

                continue;
            }

            if (validUntil < dateTo) {
                toRanges.push(validUntil);
                fromRanges.push(dateFrom);
                break;
            }

            toRanges.push(dateTo);
            fromRanges.push(dateFrom);
        }
    }

    const returnObj = {
        from: fromRanges,
        to: toRanges,
    };

    return returnObj;
};

export const resolveCustomRange = (options: ResolveCustomRangeOptions) => {
    if (options.timeRange.from.length !== options.timeRange.to.length) {
        throw new Error(`incorrect input dates`);
    }

    // function variables

    const returnDates: {
        from: any[];
        to: any[];
    } = {
        from: [],
        to: [],
    };

    /* ----------------------------- logic for DATE ----------------------------- */

    if (options.type === `DATE`) {
        for (let j = 0; j < options.timeRange.from.length; j++) {
            options.timeRange.from[j] = new Date(options.timeRange.from[j]);
            options.timeRange.to[j] = new Date(options.timeRange.to[j]);
        }

        for (let i = 0; i < options.timeRange.from.length; i++) {
            if (options.timeRange.to[i] >= options.activationDate) {
                if (options.activationDate > options.timeRange.from[i]) {
                    returnDates.from.push(options.activationDate);
                    returnDates.to.push(options.timeRange.to[i]);
                    continue;
                }
                returnDates.from.push(options.timeRange.from[i]);
                returnDates.to.push(options.timeRange.to[i]);
            }
        }
    }

    /* ----------------------------- logic for WEEK ----------------------------- */
    if (options.type === `WEEK`) {
        let returnDatesTo: any[] = [];
        let returnDatesFrom: any[] = [];

        const returnWeekDatesObj: {
            from: any;
            to: any;
        }[] = [];

        for (let i = 0; i < options.timeRange.to.length; i++) {
            const result = convertWeekOccurenceString(
                {
                    from: options.timeRange.from[i],
                    to: options.timeRange.to[i],
                },
                options.activationDate,
                options.count,
                options.duration,
            );

            if (!result) {
                return;
            }

            returnDatesTo.push(result.to);
            returnDatesFrom.push(result.from);
        }

        returnDatesTo = returnDatesTo.flat();
        returnDatesFrom = returnDatesFrom.flat();

        for (let i = 0; i < returnDatesTo.length; i++) {
            returnWeekDatesObj.push({
                from: returnDatesFrom[i],
                to: returnDatesTo[i],
            });
        }

        returnWeekDatesObj.sort((a, b) => a.from - b.from);

        for (const dateObj of returnWeekDatesObj) {
            returnDates.to.push(dateObj.to);
            returnDates.from.push(dateObj.from);
        }
    }

    return returnDates;
};
