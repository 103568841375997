export interface DropdownItem {
    label: string;
    value: string;
    roundTimeUnits?: RoundTimeUnit[];
}

export interface RoundTimeUnit {
    label: string;
    value: string;
}

export const roundTypes = [
    {
        label: `Brak`,
        value: `strict`,
    },
    {
        label: `W górę`,
        value: `inclusive`,
    },
    {
        label: `W dół`,
        value: `exclusive`,
    },
];

export const timeUnits: DropdownItem[] = [
    {
        label: `Minuty`,
        value: `minutes`,
        roundTimeUnits: [
            {
                label: `Minuty`,
                value: `minutes`,
            },
        ],
    },
    {
        label: `Godziny`,
        value: `hours`,
        roundTimeUnits: [
            {
                label: `Minuty`,
                value: `minutes`,
            },
            {
                label: `Godziny`,
                value: `hours`,
            },
        ],
    },
    {
        label: `Dni`,
        value: `days`,
        roundTimeUnits: [
            {
                label: `Minuty`,
                value: `minutes`,
            },
            {
                label: `Godziny`,
                value: `hours`,
            },
            {
                label: `Dni`,
                value: `days`,
            },
        ],
    },
    {
        label: `Miesiące`,
        value: `months`,
        roundTimeUnits: [
            {
                label: `Minuty`,
                value: `minutes`,
            },
            {
                label: `Godziny`,
                value: `hours`,
            },
            {
                label: `Dni`,
                value: `days`,
            },
            {
                label: `Miesiące`,
                value: `months`,
            },
        ],
    },
    {
        label: `Lata`,
        value: `years`,
        roundTimeUnits: [
            {
                label: `Minuty`,
                value: `minutes`,
            },
            {
                label: `Godziny`,
                value: `hours`,
            },
            {
                label: `Dni`,
                value: `days`,
            },
            {
                label: `Miesiące`,
                value: `months`,
            },
            {
                label: `Lata`,
                value: `years`,
            },
        ],
    },
];

export const RoundTimeUnits = [
    {
        label: `Minuty`,
        value: `minutes`,
    },
    {
        label: `Godziny`,
        value: `hours`,
    },
    {
        label: `Dni`,
        value: `days`,
    },
    {
        label: `Miesiące`,
        value: `months`,
    },
    {
        label: `Lata`,
        value: `years`,
    },
];

export const isBearer = [
    {
        label: `Nie`,
        value: false,
    },
    {
        label: `Tak`,
        value: true,
    },
];

export const changeAllowed = [
    {
        label: `Nie`,
        value: false,
    },
    {
        label: `Tak`,
        value: true,
    },
];

export const customRange = [
    {
        label: `Czasowy`,
        value: `time`,
    },
    {
        label: `Zdefiniowany przedział`,
        value: `custom`,
    },
    {
        label: `Odcinkowy`,
        value: `distance`
    }
];

// export const customRange = [
//     {
//         label: `Czasowy`,
//         value: `customTime`,
//     },
//     {
//         label: `Okresowy`,
//         value: `periodic`,
//     },
//     {
//         label: `Dni tygodnia`,
//         value: `weekly`,
//     },
// ];
