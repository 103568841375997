import * as fns from 'date-fns';

export const getParsedWeeklyTimeValues = (timeToParse: string) => {
    const time = timeToParse.split(` `);
    const timeVariables = time[1].split(`:`);

    const myTime = {
        day: time[0],
        seconds: Number(timeVariables[2]),
        minutes: Number(timeVariables[1]),
        hours: Number(timeVariables[0]),
    };

    return myTime;
};
