/* eslint-disable max-depth */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Api } from '../../api/src/Api';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: `root` })
export class RefreshTokenService {
    adminTimeout: any;
    userTimeout: any;

    constructor(private readonly apiService: Api) {}

    async refreshUser() {
        const token = localStorage.getItem(`token`);

        if (token) {
            await this.clearUserTimeout();
            const decodedToken = jwt_decode(token) as any;

            if (decodedToken?.exp) {
                const timeToTokenExpiration = decodedToken.exp * 1000 - Date.now();

                if (timeToTokenExpiration <= 30000) {
                    const accessToken = (await this.apiService.users
                        .usersControllerRefreshToken({
                            credentials: `include`,
                        })
                        .catch(() => {})) as any;

                    if (accessToken) {
                        const decodedNewAccessToken = jwt_decode(accessToken.data.token) as any;

                        if (accessToken.data.token) {
                            localStorage.setItem(`token`, accessToken.data.token);
                        }

                        this.userTimeout = setTimeout(async () => {
                            void this.refreshUser();
                        }, decodedNewAccessToken.exp * 1000 - Date.now());
                    }

                    return;
                }

                const refreshTokenOffset = 25 * 1000; // time in seconds before token expires
                const timeToRefreshToken = timeToTokenExpiration - refreshTokenOffset;
                this.userTimeout = setTimeout(async () => {
                    void this.refreshUser();
                }, timeToRefreshToken);
            }
        }
    }

    async refreshAdmin() {
        const token = localStorage.getItem(`admin-token`);

        if (token) {
            await this.clearAdminTimeout();

            const decodedToken = jwt_decode(token) as any;

            if (decodedToken?.exp) {
                const timeToTokenExpiration = decodedToken.exp * 1000 - Date.now();

                if (timeToTokenExpiration <= 30000) {
                    const accessToken = (await this.apiService.adminAuth
                        .adminAuthControllerRefreshToken({
                            credentials: `include`,
                        })
                        .catch(() => {})) as any;

                    if (accessToken) {
                        const decodedNewAccessToken = jwt_decode(accessToken.data.token) as any;

                        if (accessToken.data.token) {
                            localStorage.setItem(`admin-token`, accessToken.data.token);
                        }

                        this.adminTimeout = setTimeout(async () => {
                            void this.refreshAdmin();
                        }, decodedNewAccessToken.exp * 1000 - Date.now());
                    }

                    return;
                }

                const refreshTokenOffset = 25 * 1000; // time in seconds before token expires
                const timeToRefreshToken = timeToTokenExpiration - refreshTokenOffset;
                this.adminTimeout = setTimeout(async () => {
                    void this.refreshAdmin();
                }, timeToRefreshToken);
            }
        }
    }

    async clearAdminTimeout() {
        try {
            clearTimeout(this.adminTimeout);
            // eslint-disable-next-line no-empty
        } catch {}
    }

    async clearUserTimeout() {
        try {
            clearTimeout(this.userTimeout);
            // eslint-disable-next-line no-empty
        } catch {}
    }
}
