/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable line-comment-position */
/* eslint-disable no-inline-comments */
/* eslint-disable prefer-destructuring */
import * as fns from 'date-fns';

// - Do końca dnia = 1 day day-exclusive
// - Dziś 15:30 - Jutro 15:59 = 1 day hour-exclusive
// - Dziś 15:30 - Jutro 14:59 = 1 day hour-exclusive
// - Na wszystkie tygodnie rozpoczęte w miesiącu = 1 months week-inclusive
// - Do końca bieżącego miesiąca = 1 month month-exclusive
// - Do końca następnego miesiąca = 2 month month-exclusive
// - Poniedziałkowo-środowo-piątkowy = 5 psp

export const getParsedTime = (startDate: Date, timeToParse: string) => {
    const timeIntervals = [`minutes`, `hours`, `days`, `months`, `years`];
    const timeIntervalTypes = [`inclusive`, `exclusive`];
    const timeToParseArr = timeToParse.split(` `);

    let returnDate; // return date value

    //  interval; // eg. 1 2 30
    //  timeInterval; // eg. days hours weeks
    //  intervalPrefix; // eg. days hours weeks in weeks-exclusive
    let intervalType; // eg. inclusive, exclusive, strict

    /* ------------------------------ format checks ----------------------------- */

    // check if the string has correct length
    if (timeToParseArr.length !== 3) {
        return undefined;
    }
    // check the interval integer eg. 1, -2
    const interval = Number(timeToParseArr[0]);

    if (!interval || interval < 0) {
        return undefined;
    }
    // check the interval time eg. days
    const timeInterval = timeToParseArr[1];

    if (!timeIntervals.includes(timeInterval)) {
        return undefined;
    }
    // check the interval type and its prefix
    const intervalTypeArr = timeToParseArr[2].split(`-`);

    if (intervalTypeArr.length !== 2 && intervalTypeArr[0] !== `strict`) {
        return undefined;
    }
    // check if prefix is correct eg. days
    const [intervalPrefix] = intervalTypeArr;
    if (!timeIntervals.includes(intervalPrefix) && intervalPrefix !== `strict`) {
        return undefined;
    }
    // check if interval type is correct eg. exclusive
    if (intervalPrefix !== `strict`) {
        intervalType = intervalTypeArr[1];

        if (!timeIntervalTypes.includes(intervalType)) {
            throw new Error(`intervalType incorrect`);
        }
    }

    /* ------------------------------ logic  ----------------------------- */

    startDate = fns.add(startDate, {
        [timeInterval]: interval,
    });

    const minutes = startDate.getMinutes();
    const hours = startDate.getHours();
    const day = startDate.getDate();
    const month = startDate.getMonth();
    const year = startDate.getFullYear();

    if (intervalType === `inclusive`) {
        switch (intervalPrefix) {
            case `minutes`:
                returnDate = new Date(year, month, day, hours, minutes + 1, 0);
                break;
            case `hours`:
                returnDate = new Date(year, month, day, hours + 1, 0, 0);
                break;
            case `days`:
                returnDate = new Date(year, month, day + 1, 0, 0, 0);
                break;
            case `months`:
                returnDate = new Date(year, month + 1, 1, 0, 0, 0);
                break;
            case `years`:
                returnDate = new Date(year + 1, 0, 1, 0, 0, 0);
                break;
            default:
                returnDate = new Date();
                break;
        }
    }

    if (intervalType === `exclusive`) {
        switch (intervalPrefix) {
            case `minutes`:
                returnDate = new Date(year, month, day, hours, minutes, 0);
                break;
            case `hours`:
                returnDate = new Date(year, month, day, hours, 0, 0);
                break;
            case `days`:
                returnDate = new Date(year, month, day, 0, 0, 0);
                break;
            case `months`:
                returnDate = new Date(year, month, 1, 0, 0, 0);
                break;
            case `years`:
                returnDate = new Date(year, 0, 1, 0, 0, 0);
                break;
            default:
                returnDate = new Date();
                break;
        }
    }
    if (intervalPrefix === `strict`) {
        returnDate = startDate;
        return startDate;
    }

    if (!returnDate) {
        return;
    }

    returnDate = fns.sub(returnDate, {
        seconds: 1,
    });

    return returnDate;
};

// 1 months months-exclusive
// 1 months months-inclusive

// 2 months months-exclusive
// 2 months months-inclusive

// 1 days days-inclusive
// 1 days days-exclusive

// 2 days days-inclusive
// 2 days days-exclusive

// 1 days hours-inclusive
// 1 days hours-exclusive

// 12 hours hours-inclusive
// 12 hours hours-exclusive
